import Line from '@/labtest/presentation/atoms/Line';
import { MdClose } from 'react-icons/md';
import styles from './ModalHeader.module.css';

interface ModalHeader {
	name?: string;
	title: string;
	titleColor?: string;
	closeModal: () => void;
}

const ModalHeader = ({ name, title, titleColor, closeModal }: ModalHeader): JSX.Element => {
	return (
		<>
			<div className={styles.dialog_head}>
				<p className={`${titleColor ? titleColor : 'ltGreyscale900'} ltbody_xlg__semibold `}>{title}</p>
				<MdClose
					size={22}
					className='cursor_pointer errorBase'
					onClick={(e) => {
						e.stopPropagation();
						closeModal();
					}}
				/>
			</div>
			{!name && <Line />}
		</>
	);
};

export default ModalHeader;
