import LoginAffordablePriceSvg from '@labtest/ltassets/login/LoginAffordablePriceSvg.svg';
import LoginDigitalReportSvg from '@labtest/ltassets/login/LoginDigigalReportSvg.svg';
import LoginProcessSvg from '@labtest/ltassets/login/LoginProcessSvg.svg';

import americanexpress from '@labtest/ltassets/payment_methods/americanexpress.svg';
import bankasia from '@labtest/ltassets/payment_methods/bankasia.svg';
import bkash from '@labtest/ltassets/payment_methods/bkash.svg';
import dmoney from '@labtest/ltassets/payment_methods/dmoney.svg';
import mastercard from '@labtest/ltassets/payment_methods/mastercard.svg';
import mcash from '@labtest/ltassets/payment_methods/mcash.svg';
import nexus from '@labtest/ltassets/payment_methods/nexus.svg';
import nogod from '@labtest/ltassets/payment_methods/nogod.svg';
import rocket from '@labtest/ltassets/payment_methods/rocket.svg';
import surecash from '@labtest/ltassets/payment_methods/surecash.svg';
import upay from '@labtest/ltassets/payment_methods/upay.svg';
import visa from '@labtest/ltassets/payment_methods/visa.svg';

import { PrescriptionsSVG } from '@/services/doc-consultation/utils/iconBase';
import { FaReceipt } from 'react-icons/fa';
import { FaUserDoctor } from 'react-icons/fa6';
import { MdAccountBalanceWallet, MdCalendarMonth, MdDashboard } from 'react-icons/md';
import { TbLogout } from 'react-icons/tb';

export const sidebarMenus = [
	{
		id: 1,
		name: 'Dashboard',
		icon: <MdDashboard size={24} color='var(--ltPrimary)' />,
		isActive: true,
		link: '/dashboard',
		children: []
	},
	{
		id: 2,
		name: 'Queue',
		icon: <FaUserDoctor size={24} color='var(--ltPrimary)' />,
		isActive: false,
		link: '/dashboard/todays-queue',
		children: []
	},
	{
		id: 6,
		name: 'Upcoming Schedule',
		icon: <MdCalendarMonth size={24} color='var(--ltPrimary)' />,
		isActive: false,
		link: '/dashboard/upcoming-schedule',
		children: []
	},
	{
		id: 7,
		name: 'Pending Prescriptions',
		icon: <PrescriptionsSVG size={24} color='var(--ltPrimary)' />,
		isActive: false,
		link: '/dashboard/pending-prescriptions',
		children: []
	},
	{
		id: 8,
		name: 'Consultation History',
		icon: <MdAccountBalanceWallet size={24} color='var(--ltPrimary)' />,
		isActive: false,
		link: '/dashboard/consultation-history',
		children: []
	},
	{
		id: 9,
		name: 'Earning History',
		icon: <MdAccountBalanceWallet size={24} color='var(--ltPrimary)' />,
		isActive: false,
		link: '/dashboard/earning-history',
		children: []
	},
	{
		id: 10,
		name: 'My Withdraw',
		icon: <FaReceipt size={24} color='var(--ltPrimary)' />,
		isActive: false,
		link: '/dashboard/my-withdraw',
		children: []
	},
	{
		id: 11,
		name: 'Profile',
		icon: <FaUserDoctor size={24} color='var(--ltPrimary)' />,
		isActive: false,
		link: '/dashboard/profile',
		children: []
	},
	{
		id: 13,
		name: 'Video-Call',
		icon: <FaUserDoctor size={24} color='var(--ltPrimary)' />,
		isActive: false,
		link: '/other',
		children: []
	},
	{
		id: 12,
		name: 'Logout',
		icon: <TbLogout size={24} color='var(--ltPrimary)' />,
		isActive: false,
		link: '',
		children: []
	}
];

export const loginSlider = [
	{
		Image: LoginProcessSvg,
		title: 'Quick & Easy Ordering Process',
		description: 'Ordering is simplified with just a few clicks.'
	},
	{
		Image: LoginAffordablePriceSvg,
		title: 'Affordable Pricing',
		description: 'Enjoy cost-effective lab test options with our budget-friendly pricing'
	},
	{
		Image: LoginDigitalReportSvg,
		title: 'Timely Digital Reports',
		description: 'Receive prompt and convenient digital reports for your lab tests.'
	}
];

export const filterByOrderData = [
	{
		id: 1,
		label: 'Popularity',
		value: 'popular:desc'
	},
	{
		id: 2,
		label: 'Price: Low to High',
		value: 'price:asc'
	},
	{
		id: 3,
		label: 'Price: High to Low',
		value: 'price:desc'
	},
	{
		id: 4,
		label: 'Discount: Low to High',
		value: 'discount:asc'
	},
	{
		id: 5,
		label: 'Discount: High to Low',
		value: 'discount:desc'
	},
	{
		id: 6,
		label: 'Name (A to Z)',
		value: 'alphabet:asc'
	}
];

export const filterByPriceData = [];

export const OnlinePaymentMethods = [
	{ name: bkash },
	{ name: nogod },
	{ name: rocket },
	{ name: upay },
	{ name: americanexpress },
	{ name: bankasia },
	{ name: dmoney },
	{ name: mastercard },
	{ name: surecash },
	{ name: nexus },
	{ name: visa },
	{ name: mcash }
];
